.home-content {
  background-image: url('../../assets/home-bg.jpeg');
  background: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8) ),url('../../assets/home-bg.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 120px);
  overflow: hidden;

  /* height: calc(100vh - 120px) !important; */
  padding-top: 120px;
}
.home-img-container {
  position: relative;
}

.home-img-container img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.title-broomswap {
  font-family: Wonderworld Regular !important;
  font-size: 96px !important;
  font-weight: 400 !important;
  color: #E58900 !important
}

.description-broomswap {
  font-size: 24px !important;
  font-weight: 400 !important;
  color: #D6CFC3 !important
}

.home-group-btn {
  margin-top: 60px;
}

.home-group-btn button:first-child {
  margin-right: 36px;
}

.home-img-container img:first-child {
  animation: rotation 20s infinite linear;
}

.home-img-container img:last-child {
  animation: broomfly 5s infinite linear;
}

.home-feature-container {
  background-color: #141413;
  padding-top: 120px;
  padding-bottom: 80px;
}

.home-feature-container .title {
  font-family: Wonderworld Regular !important;
  font-size: 64px !important;
  font-weight: 400 !important;
  color: #E58900 !important
}

.home-feature-container .description {
  font-size: 20px !important;
  font-weight: 400 !important;
  color: #D6CFC3 !important;
  margin-top: 8px
}

.home-feature-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}

.home-feature-item {
  border-radius: 5px;
  background: linear-gradient(to bottom, #2F2F2C,#141413);
  border-radius: 36px;
  text-align: center;
  padding: 16px 16px 39px 16px;
  
}
.home-feature-img {
  width: 304px;
  height: 304px;
  border-radius: 24px;
  border: 1px solid #3F3A36;
  overflow: hidden;
}

.home-feature-item p {
  font-family: Wonderworld Regular !important;
  font-size: 32px !important;
  font-weight: 400 !important;
  background: linear-gradient(to left top, #F7EBB7,#EDBF2B,#E58900);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 1px solid transparent
  
}

.home-feature-wrap {
  margin: 0 24px !important;
  padding: 1px;
  position: relative;
  background:transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
}
.home-feature-wrap:hover {
  background:linear-gradient(to left top, #F7EBB7,#EDBF2B,#E58900);
  cursor: pointer;
}

.home-feature-wrap:hover img {
  transform: scale(1.1)
}
.home-feature-wrap:hover p {
  transform: scale(1.1)
}

.home-partner-container {
  background-image: url('../../assets/merlin-bg.jpeg');
  background: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8) ),url('../../assets/merlin-bg.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 120px);
  padding-top: 120px
}

.home-partner-container .title {
  font-family: Wonderworld Regular !important;
  font-size: 64px !important;
  font-weight: 400 !important;
  color: #E58900 !important
}

.home-partner-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.home-partner-item {
  padding: 24px;
  margin: 24px;
  border-radius: 5px;
  background-color: #FFFFFF0D;
  width: fit-content;
}

.home-community-container {
  background-color: #141413;
  padding-top: 120px;
  padding-bottom: 160px;
}

.home-community-container .title {
  font-family: Wonderworld Regular !important;
  font-size: 64px !important;
  font-weight: 400 !important;
  color: #E58900 !important
}

.home-community-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.home-community-item {
  margin: 0 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 1400px) {
  .home-img-container {
    width: 720px;
    height: 720px;
  }
}

@media screen and (max-width: 1350px) {
  .home-img-container {
    width: 600px;
    height: 600px;
  }

  .home-img-container img:first-child {
    width: 600px;
    height: 661px;
  }

  .home-img-container img:last-child {
    width: 410px;
    height: 479px;
  }
}


@media screen and (max-width: 1150px) {
  .home-img-container {
    width: 600px;
    height: 600px;
  }

  .title-broomswap {
    font-size: 60px !important;
  }

  .description-broomswap {
    font-size: 16px !important;
  }

  .home-img-container img:first-child {
    width: 400px;
    height: 440px;
  }

  .home-img-container img:last-child {
    width: 280px;
    height: 327px;
  }
}

@media screen and (max-width: 500px) {
  .home-content {
    height: fit-content;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .home-img-container {
    width: 100%;
    height: unset;
    margin-top: 150px;
    margin-bottom: 170px;
  }

  .home-intro-content {
    text-align: center;
  }
  .title-broomswap {
    font-size: 48px !important;
    width: inherit;
  }

  .home-group-btn {
    margin-top: 24px !important;
    margin-bottom: 60px;
    display: flex;
    width: inherit;
  }

  .home-group-btn button:first-child {
    margin-right: auto;
  }
  .home-img-container img:first-child {
    width: 100% !important;
    height: auto !important;
  }

  .home-img-container img:last-child {
    width: 70% !important;
    height: auto !important;
  }

  .home-feature-container {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .home-feature-container .title {
    font-size: 36px !important;
  }

  .home-feature-container .description {
    font-size: 16px !important;
  }

  .home-feature-wrap {
    margin-bottom: 48px !important;
  }

  .home-community-container {
    padding-top: 17px !important;
    padding-bottom: 78px !important;
  }
  .home-community-container .title {
    font-size: 36px !important;
  }

  .home-community-item img {
    width: 72px;
  }

  .home-partner-container {
    padding-top: 80px !important;
  }
  .home-partner-container .title {
    font-size: 36px !important;
  }

  .home-partner-item {
    padding: 12px !important;
    margin: 12px !important;
  }

  .home-partner-item img {
    width: 144px;
    height: 37px;
  }
}

@keyframes rotation {
  to {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(359deg);
  }
}

@keyframes broomfly {
  0% {
    transform: rotate(0deg);
    transform-origin: 0px 0px;
  }
  50% {
    transform: rotate(5deg);
    transform-origin: 240px 240px;
  }
  100% {
    transform: rotate(0deg);
    transform-origin: 0px 0px;
  }
}