.staking-container {
  background-color: #141413;
  height: calc(100vh - 91px);
  padding-top: 91px;
  padding-left: 15%;
  padding-right: 15%;
  /* display: flex;
  flex-direction: column;
  justify-content: 'center';
  align-items: center; */
}

.staking-text {
  display: flex;
  flex-direction: column !important;
  margin-top: 40px;
  margin-bottom: 24px;
}

.staking-text .staking-title {
  font-size: 40px;
  font-weight: 500;
  color: #FBF6EC
}

.staking-text .staking-description {
  font-size: 16px;
  font-weight: 400;
  color: #D6CFC3
}

.staking-tag {
  margin-bottom: 24px;
}

.pool-title {
  font-size: 24px !important;
  font-weight: 500 !important;
  color: #FBF6EC !important;
  margin-bottom: 24px !important;
}

.list-pool-container .MuiPaper-root {
  border-radius: 12px;
  border: 1px solid #3F3A36 !important
}
.list-pool-container thead tr {
  background-color: #201F1E !important;
}

.list-pool-container thead tr th {
  border-bottom-color: #3F3A36 !important;
}

.list-pool-container tbody tr {
  background-color: #141413 !important;
}

.list-pool-container tbody tr td {
  color: #FBF6EC;
  border-bottom-color: #3F3A36 !important;
}

/* .list-pool-container .MuiTouchRipple-root:focus-visible {
  background-color: transparent !important;
} */

.list-pool-container .Mui-TableHeadCell-Content-Wrapper {
  color: #95928D !important;
}

.list-pool-container thead tr th {
  background-color: #141413 !important;
}

.no-record {
  color: white;
  padding: 24px;
}

.small-box {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: #201F1E;
  border-radius: 8px;
  cursor: pointer;
}

.small-box p {
  font-size: 14px;
  font-weight: 500;
  color: #FBF6EC;
  /* margin-right: 8px; */
}

.expand-box {
  padding: 24px;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  background-color: #201F1E;
  border-radius: 8px;
}

.expand-box .title {
  font-size: 16px;
  font-weight: 500;
  color: #FBF6EC
}

.expand-box .value {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 28px;
}

.expand-box .value p {
  font-size: 24px;
  font-weight: 500;
  color: #FBF6EC;
  margin-left: 8px;
}

.expand-box .value img {
  width: 24px;
}

.expand-box .btn {
  
}

.expand-box .balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.expand-box .balance .label {
  color: #95928D;
  font-size: 14px;
  font-weight: 500;
}

.expand-box .balance .value {
  color: #FBF6EC;
  font-size: 14px;
  font-weight: 500;
  margin: 0 !important;
}

.expand-box .input-stake {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #141413 !important;
  border-radius: 12px;
  border: 1px solid #3F3A36;
  padding: 8px 12px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.expand-box .input-stake input {
  background-color: transparent;
  outline: none;
  border: 0px solid black;
  color: #FBF6EC;
  font-size: 24px;
  font-weight: 500;
}

.slice-amount {
  /* white-space: nowrap; 
  max-width: 40%; 
  overflow: hidden;
  text-overflow: ellipsis; 
  display: inline-block;
  margin-right: 5px; */
}

.stake-inform-rule {
  color: #B4AB9B !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  margin-top: 12px !important;
}

@media screen and (max-width: 1600px) {
  .expand-box .input-stake input {
    width: 120px;
  }
}