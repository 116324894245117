.menu-container {
  position: fixed;
  top: 0;
  width: calc(100vw - 240px);
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 24px 120px;
  z-index: 2;
}

.menu-link-container {
  display: flex;
  align-items: center;
  margin-left: 60px;
}

.menu-link-container .menu-link {
  margin-right: 45px;
  color: #B4AB9B;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}

.menu-link-container .menu-link:hover {
  color:#E58900
}

.menuMobileContainer {
  display: none;
}

.mobile-menu-icon {
  padding: 6px !important;
  border-radius: 8px !important;
  background: #3F3A36 !important;
}

.menu-active {
  color: white !important
}

@media screen and (max-width: 550px) {
  .menu-desktop {
    display: none !important;
  }

  .menu-bar {
    width: 90%;
  }
  .menuMobileContainer {
    display: block;
  }

  .menu-container {
    padding: 24px 20px;
    width: 100%;
  }

  .menu-content {
    display: flex !important;
    justify-content: space-between;
  }

  .btn-menu-mobile {
    margin-right: 40px !important;
  }

  .menu-list ul {
    background: #141413;
    width: 280px;
    height: 255px;
  }

  .menu-list li {
    color: #B4AB9B;
    font-size: 16px;
    font-weight: 700;
  }

  .menu-list .golden-btn {
    margin-top: 12px !important;
    margin-left: 12px !important;
  }
}