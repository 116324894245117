.slippage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #201F1E;
  border: 1px solid #3F3A36;
  border-radius: 8px;
  padding: 8px;
  color: white;
  height: fit-content;
  position: absolute;
  right: 0;
  top: 10px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
}

.slippage-content .MuiPopover-paper {
  background: transparent !important;
}

.slippage-content .setting-box {
  background-color: #141413;
  border-radius: 12px;
  border: 1px solid #3F3A36;
  padding: 12px;
  flex-direction: column;
}

.slippage-content .divider-line {
  height: 1px;
  background-color: #3F3A36;
  width: 100%;
  margin: 12px 0;
}

.slippage-content .content-box {

}

.slippage-content .content-box .content-box-title {
  color: #FBF6EC;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 12px;
}

.slippage-content .content-box .input-box {
  background-color: #141413;
  border: 1px solid #3F3A36;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 4px;
}

.slippage-content .content-box .input-box input {
  color: white;
  background-color: transparent;
  border: none;
  outline: none;
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
}

.slippage-content .content-box .input-box input:disabled {
  color: #3F3A36
}

.slippage-content .content-box .input-box p {
  color: #FBF6EC;
  font-size: 12px;
  font-weight: 500;
}

.slippage-content .content-box-mode {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px
}

.slippage-content .setting-mode {
  background-color: #201F1E;
  border-radius: 8px;
  border: 1px solid #3F3A36;
  display: flex;
  justify-content: space-around;
  padding: 4px;
}

.slippage-content .setting-mode .mode-option {
  padding: 4px !important;
  color: #95928D !important;
  background-color: transparent !important;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px !important;
  cursor: pointer;
}

.slippage-content .setting-mode .chosen-mode {
  background-color: #141413 !important;
  color: #FBF6EC !important
}