.select-token-container .MuiDialog-paper {
  background-color: #141413 !important;
  color: #D6CFC3;
  height: 50%;
  width: 500px;
  padding: 0 24px;
  border-radius: 24px;
  padding-bottom: 24px;
}

.select-token-container .MuiDialogTitle-root {
  text-align: center;
}

.select-token-content {
  padding: 12px;
  border-radius: 12px;
  border: 1px solid #3F3A36;
}

.select-token-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 12px;
  border-bottom: 1px solid #3F3A36;
}

.select-token-item:last-child {
  border-bottom: 1px solid transparent;
}
.select-token-item img {
  width: 24px;
  margin-right: 5px;
}

.select-token-item p:first-child {
  font-size: 16px;
  font-weight: 700;
  color: #FBF6EC
}

.select-token-item p:last-child {
  font-size: 12px;
  font-weight: 500;
  color: #B4AB9B
}

.select-token-search {
  display: flex;
  align-items: center;
  border: 1px solid #3F3A36;
  border-radius: 12px;
  padding: 8px 12px;
}

.select-token-search .search-token {
  background-color: transparent;
  width: 100%;
  border: none;
  outline: none;
  color: #D6CFC3;
  margin-left: 8px;
}

.common-token {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  gap: 17px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.common-token .common-token-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.common-token .common-token-title p {
  color: #FBF6EC;
  font-size: 16px;
  font-weight: 500;
  text-wrap: nowrap;
}

.common-token .common-token-title div {
  height: 1px;
  width: 100%;
  background-color: #3F3A36;
}

.small-box img {
  width: 24px;
  margin-right: 3px;
}

.common-token-list {
  display: flex;
  gap: 8px;
  width: 100%;
}