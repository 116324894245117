.footer-container {
  background: #201F1E;
  padding: 100px 120px 31px 120px;
}

.footer-list {
  display: flex !important;
  flex-direction: column !important;
}

.footer-list .title {
  font-weight: 600;
  font-size: 20px;
  color: #FBF6EC;
  margin-bottom: 24px;
}

.footer-list .item {
  font-weight: 500;
  font-size: 16px;
  color: #B4AB9B;
  margin-bottom: 12px;
  cursor: pointer;
}

.list-icon img {
  width: 56px;
  margin-right: 32px;
}

@media screen and (max-width: 500px) {
  .footer-container {
    padding: 40px 0px 98px 12px !important;
    position: relative;
  }

  .footer-content {
    flex-direction: column !important;
  }

  .footer-list {
    margin-top: 40px !important;
  }

  .footer-broom {
    position: absolute;
    bottom: 39px;
  }
}
