.tag-info-container {
  padding: 24px;
  background-color: #201F1E;
  border: 1px solid #3F3A36;  
  border-radius: 8px;
}

.tag-info-label {
  font-weight: 500;
  font-size: 16px;
  color: #D6CFC3
}

.tag-info-value {
  font-weight: 500;
  font-size: 32px;
  color: #FBF6EC
}