.snack-bar-container {
  
}

.snack-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #141413;
  width: 315px;
  height: 163px;
  border-radius: 24px;
}

.snack-bar p {
  color: #FBF6EC;
  font-size: 16px;
  font-weight: 700;
  margin-top: 16px;
}

.snack-bar .MuiSnackbarContent-root {
  height: 50px;
  font-size: 16px;
  font-weight: 400;
}

.snack-bar-success .MuiSnackbarContent-root {
  background-color: #0096db !important;
}

.snack-bar-error .MuiSnackbarContent-root {
  background-color: #ca0202 !important;
}