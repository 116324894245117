.swap-container {
  background-color: #141413;
  min-height: calc(100vh - 91px);
  height: auto;
  padding-top: 91px;
  padding-left: 15%;
  padding-right: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.swap-container .tab-label {
  font-size: 24px;
  font-weight: 500;
  color: #B4AB9B !important;
  text-transform: none;
}

.swap-container .Mui-selected {
  color: #FBF6EC !important;
}
.swap-container .MuiTabs-indicator {
  background: linear-gradient(to left top, #F7EBB7,#EDBF2B,#E58900) !important;
  /* width: 45% !important;
  left: 0;
  right: 0;
  margin: auto; */
}

.tabswap-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.tabswap-box {
  padding: 12px;
  background-color: #201F1E;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:12px;
  border-radius: 12px;
}

.tabswap-box .icon-swap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
}

.tabs-container {
  margin-bottom: 20px;
  width: 448px;
}

.tabswap-container .price-exchange {
  background-color: #201F1E;
  border-radius: 12px;
  padding: 8px 12px;
}

.tabswap-container .price-exchange p {
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 400;
}

.tabswap-container .price-exchange span {
  color: #B4AB9B;
  font-size: 12px;
  font-weight: 400;
}

.tabswap-container .expiry-box {
  border-radius: 12px;
  background-color: #201F1E;
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  align-items: center;
}

.tabswap-container .expiry-box .expiry-title {
  color:#FBF6EC;
  font-size: 16px;
  font-weight: 400;
}

.tabswap-container .expiry-box .expiry-options {
  background-color: #141413;
  padding: 6px;
  border-radius: 8px;
  gap: 8px;
  display: flex;
  align-items: center;
  border: 1px solid #3F3A36;
}

.tabswap-container .expiry-box .expiry-options .time-option {
  font-size: 16px;
  font-weight: 500;
  color: #95928D;
  padding: 6px;
  background-color: transparent;
  cursor: pointer;
  border-radius: 4px;
}

.tabswap-container .expiry-box .expiry-options .chosen-time {
  color: #FBF6EC;
  background-color: #201F1E;
}

.wrap-comming-soon {
  height: 400px;
}

.wrap-comming-soon .coming-soon-container {
  margin-top: 50px;
  height: fit-content !important;
}

@media screen and (max-width: 500px) {
  .swap-container { 
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .tabs-container {
    width: 90% !important;
  }
}
