.wallet-dialog-container .MuiDialog-paper {
  background-color: #141413 !important;
  color: #D6CFC3;
}

.wallet-dialog-container .MuiDialogTitle-root {
  text-align: center;
}

.wallet-dialog-content {
  padding-bottom: 20px;
}

.wallet-dialog-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 24px;
  background-color: #201F1E;
  border: 1px solid transparent;
  border-radius: 8px;
  margin: 10px 20px !important;
}

.wallet-dialog-item img {
  width: 50px;
  margin-right: 20px;
}

.wallet-dialog-item:hover {
  border: 1px solid #3F3A36;
}

.wallet-dialog-item p {
  font-size: 26px;
  font-weight: 600;
}