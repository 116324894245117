.tokenlimit-container {
  width: 408px;
  padding: 8px;
  height: 66px;
  border-radius: 12px;
  background: #141413;
}

.tokenlimit-content {
  display: flex !important;
  flex-direction: column !important;
  gap: 4px
}

.tokenlimit-content .balance {
  color: #B4AB9B !important;
  font-size: 12px;
  font-weight: 500;
}

.tokenlimit-content .input-amount {
  color: #FBF6EC !important;
  font-size: 32px;
  font-weight: 500;
  background: transparent;
  border: 0;
  outline: 0;
  text-align: right;
  width: 200px;
}

.tokenlimit-content .price-dolar {
  color: #B4AB9B !important;
  font-size: 12px;
  font-weight: 500;
}

.tokenlimit-box-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tokenlimit-box-token {
  background-color: #201F1E;
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  width: 116px;
  height: 31px;
  cursor: pointer;
}

.tokenlimit-box-token .token {
  display: flex;
  align-items: center;
}

.tokenlimit-box-token .token img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

.tokenlimit-box-token .token .where-title {
  color: #B4AB9B;
  font-size: 10px;
  font-weight: 400;
}

.tokenlimit-box-token .token .name {
  color: #FBF6EC;
  font-size: 16px;
  font-weight: 700;
}

.tokenlimit-box-token .icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tokenlimit-number {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tokenlimit-container .no-token {
  color: #B4AB9B
}

.tokenlimit-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #B4AB9B;
  font-size: 12px;
  font-weight: 500;
}

.tokenlimit-text .swap-icon {
  width: 24px;
  cursor: pointer;
}

.tokenlimit-text .tokenlimit-inform {
  display: flex;
  align-items: center;
}

.tokenlimit-from {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.tokenlimit-from p {
  font-size: 12px !important;
  font-weight: 700 !important;
  color: #FBF6EC;
}

.tokenlimit-from img {
  width: 12px;
  height: 12px;
  margin-left: 5px;
  margin-right: 5px;
}

.tokenlimit-to {
  display: flex;
  align-items: center;
  gap:4px
}

.tokenlimit-to img {
  width: 24px;
  height: 24px;
}

.tokenlimit-to p {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #FBF6EC;
}

.tokenlimit-number input {
  background: transparent;
  outline: none;
  border: none;
  text-align: right;
  color: #FBF6EC;
  font-size: 32px;
  font-weight: 500;
  width: 200px;
}

@media screen and (max-width: 500px) {
  .tokenlimit-container {
    width: 308px !important;
  }
}