.coming-soon-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #141413;
}
.title-coming-soon {
  font-family: Wonderworld Regular !important;
  font-size: 96px !important;
  font-weight: 400 !important;
  color: #E58900 !important
}

@media screen and (max-width: 550px) {
  .title-coming-soon {
    font-size: 60px !important;
  }

  .coming-soon-container img {
    width: 200px;
  }
}