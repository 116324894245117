.coin-info-container {
  padding: 12px;
  width: 282px;
  margin: 0 24px;
}
.coin-name {
  color: #FBF6EC;
  font-weight: 700;
  font-size: 16px;
}

.coin-logo {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}

.coin-logo img {
  width: 40px;
  border-radius: 50%;
}
.coin-code {
  color: #D6CFC3;
  font-weight: 400;
  font-size: 14px;
}

.coin-price {
  color: #FBF6EC;
  font-weight: 700;
  font-size: 20px;
}

.coin-percent {
  font-weight: 700;
  font-size: 14px;
}

.bull-time {
  color: #16B21D
}

.bear-time {
  color: red
}