.golden-btn {
  background: linear-gradient(to left top, #F7EBB7,#EDBF2B,#E58900);
  color: black !important;
  box-shadow: none;
  text-transform: none !important;
  width: 160px;
  height: 43px;
  font-size: 16px !important;
  border-radius: 12px !important;
  line-height: 20px !important;
  font-weight: 600 !important;
  font-family: 'Inter' !important;
}

.white-border-btn {
  color: white !important;
  border: 1px solid white !important;
  box-shadow: none;
  text-transform: none !important;
  width: 160px;
  height: 43px;
  font-size: 16px !important;
  border-radius: 12px !important;
  line-height: 20px !important;
  font-weight: 600 !important;
  font-family: 'Inter' !important;
}

.orange-btn {
  color: #141413 !important;
  background-color: #E58900 !important;
  width: 160px;
  height: 43px;
  font-size: 16px !important;
  border-radius: 12px !important;
  line-height: 20px !important;
  font-weight: 600 !important;
  text-transform: none !important;
}

.disabled-btn {
  cursor: not-allowed !important;
  background-color: #3F3A36 !important;
}

.full-width {
  width: 100% !important;
}