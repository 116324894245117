.penalty-dialog-container .MuiDialog-paper {
  background-color: #141413 !important;
  color: #D6CFC3;
  width: 303px;
  height: 304px;
  padding: 24px;
  border-radius: 8px;
}

.penalty-dialog-container .MuiDialogTitle-root {
  text-align: center;
}

.penalty-dialog-container .MuiDialogTitle-root p {
  color: #FBF6EC;
  font-weight: 700;
  font-size: 24px;
}

.penalty-dialog-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.penalty-dialog-description {
  width: 303px;
  color: #D6CFC3;
  font-size: 14px;
  font-weight: 500;
  margin-top: 16px;
  margin-bottom: 16px;
}

.penalty-dialog-remain-box {
  border: 1px solid #3F3A36;
  padding: 12px;
  background-color: #201F1E;
  width: 100%;
  border-radius: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.penalty-dialog-remain-box .label {
  color: #B4AB9B;
  font-size: 12px;
  font-weight: 500;
}

.penalty-dialog-remain-box .value {
  color: #FBF6EC;
  font-size: 16px;
  font-weight: 700;
}