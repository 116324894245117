.tokenswap-container {
  width: 408px;
  padding: 8px;
  height: 66px;
  border-radius: 12px;
  background: #141413;
}

.tokenswap-content {
  display: flex !important;
  flex-direction: column !important;
  gap: 4px
}

.tokenswap-content .balance {
  color: #B4AB9B !important;
  font-size: 12px;
  font-weight: 500;
}

.tokenswap-content .input-amount {
  color: #FBF6EC !important;
  font-size: 32px;
  font-weight: 500;
  background: transparent;
  border: 0;
  outline: 0;
  text-align: right;
  width: 200px;
}

.input-amount:disabled {
  color: #3F3A36 !important
}

.tokenswap-content .price-dolar {
  color: #B4AB9B !important;
  font-size: 12px;
  font-weight: 500;
}

.tokenswap-box-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tokenswap-box-token {
  background-color: #201F1E;
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  width: 116px;
  height: 31px;
  cursor: pointer;
}

.tokenswap-box-token .token {
  display: flex;
  align-items: center;
}

.tokenswap-box-token .token img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

.tokenswap-box-token .token .where-title {
  color: #B4AB9B;
  font-size: 10px;
  font-weight: 400;
}

.tokenswap-box-token .token .name {
  color: #FBF6EC;
  font-size: 16px;
  font-weight: 700;
}

.tokenswap-box-token .icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tokenswap-number {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tokenswap-container .no-token {
  color: #B4AB9B
}

@media screen and (max-width: 500px) {
  .tokenswap-container {
    width: 308px !important;
  }

  .tokenswap-content .input-amount {
    width: 100px !important;
  }
}