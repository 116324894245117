.custom-loading-container {

}

.custom-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #141413;
  /* padding: 48px 24px; */
  width: 315px;
  height: 163px;
  border-radius: 24px;
}

.custom-loading img {
  animation: loading 1.4s linear infinite;
}

.custom-loading-container p {
  color: #B4AB9B;
  font-size: 16px;
  font-weight: 700;
  margin-top: 16px;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
